import { Booking } from '@wix/ambassador-bookings-server/types';
import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  formatShortDate,
  formatShortTime,
} from '../../../../utils/dateAndTime/dateAndTime';
import { CalendarState } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';
import { Optional } from '../../../../types/types';
import { BookingAdapter } from '@wix/bookings-checkout-api';

const BASE_PARTICIPANTS_AMOUNT = 1;

export type RescheduleDetailsViewModel = {
  notificationContent: string;
  buttonText: string;
};

export const memoizedRescheduleDetailsViewModel: MemoizedViewModalFactory<
  Optional<RescheduleDetailsViewModel>
> = {
  dependencies: {
    state: ['rescheduleBookingDetails', 'selectedTimezone'],
  },
  createViewModel: createRescheduleDetailsViewModel,
};

export function createRescheduleDetailsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<RescheduleDetailsViewModel> {
  if (!state.rescheduleBookingDetails) {
    return;
  }

  const { rescheduleBookingDetails, selectedTimezone } = state;
  const { t, businessInfo, experiments } = context;
  let participantsAmount, startTime;

  if (experiments.enabled('specs.bookings.useBookingsCheckoutApi')) {
    const bookingAdapter = new BookingAdapter(rescheduleBookingDetails);
    participantsAmount = bookingAdapter.numberOfParticipants;
    startTime = bookingAdapter.startTime;
  } else {
    participantsAmount = getParticipantsAmount(
      rescheduleBookingDetails as Booking,
    );
    startTime = (rescheduleBookingDetails as Booking).bookedEntity
      ?.singleSession?.start!;
  }

  const notificationContent = t(
    participantsAmount === 1
      ? 'app.rescheduled-booking.content.single-participant'
      : 'app.rescheduled-booking.content.multi-participants',
    {
      participants_amount: participantsAmount,
      date: formatShortDate(
        startTime,
        businessInfo!.dateRegionalSettingsLocale,
        selectedTimezone,
      ),
      time: formatShortTime(
        startTime,
        businessInfo!.dateRegionalSettingsLocale,
        selectedTimezone,
      ),
    },
  );
  const buttonText = t('app.rescheduled-booking.back-button');

  return {
    notificationContent,
    buttonText,
  };
}

function getParticipantsAmount(booking: Booking) {
  const paymentSelection = booking.formInfo?.paymentSelection;

  return paymentSelection && paymentSelection[0].numberOfParticipants
    ? paymentSelection[0].numberOfParticipants
    : BASE_PARTICIPANTS_AMOUNT;
}
